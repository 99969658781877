<template>
  <div class="container">
    <div class="function-part">
      <AuthorizeUserComponent :mission-type="'completeUserInfo'"
                              @signupSuccess="onSignupSuccess"
                              @loginSuccess="onLoginSuccess"
      >
      </AuthorizeUserComponent>
    </div>
  </div>
</template>

<script>
import AuthorizeUserComponent from "../components/account/AuthorizeUserComponent";
import {MetaLogger} from "../util";
import {getMyInfo} from "../api/api";
export default {
  name: "CompleteUserInfoNew",
  components: {AuthorizeUserComponent},
  methods:{
    onLoginSuccess(){
      MetaLogger.info("login success")
      getMyInfo().then(res=> {
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        } else {
          this.$router.push('/index');
        }
      })
    },
    onSignupSuccess(){
      MetaLogger.info("signup success")
      getMyInfo().then(res=>{
        if(this.$route.query.redirect) {
          MetaLogger.info(this.$route.query.redirect);
          this.$router.push(this.$route.query.redirect);
        }
        else{
          this.$router.push('/index');
        }
      })


    }
  }
}
</script>

<style scoped>
.container{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.function-part{

}
</style>
